import React from "react";
import * as _ from "lodash";

export const ProfileHitsGridItem = (props)=> {
  const {bemBlocks, result} = props
  let url = "https://www.xing.com/profile/" + result._source.username
  const source:any = _.extend({}, result._source, result.highlight)

  return (
    <div className={bemBlocks.item().mix(bemBlocks.container("item"))} data-qa="hit">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img data-qa="image" className={bemBlocks.item("image")} src={result._source.image} referrerpolicy="no-referrer" width="188" height="188"/>
        <div data-qa="name" className={bemBlocks.item("title")} dangerouslySetInnerHTML={{__html:source.given_name + ' ' + source.family_name}}>
        </div>
      </a>
    </div>
  )
}

export const ProfileHitsListItem = (props)=> {
  const {bemBlocks, result} = props
  let url = "https://www.xing.com/profile/" + result._source.username
  const source:any = _.extend({}, result._source, result.highlight)

  return (
    <div className={bemBlocks.item().mix(bemBlocks.container("item"))} data-qa="hit">
      <div className={bemBlocks.item("poster")}>
        <img data-qa="image" src={result._source.image} referrerpolicy="no-referrer" width="94" height="94" />
      </div>
      <div className={bemBlocks.item("details")}>
        <a href={url} target="_blank" rel="noopener noreferrer"><h2 className={bemBlocks.item("title")} dangerouslySetInnerHTML={{__html:source.given_name + ' ' + source.family_name}}></h2></a>
        <h3 className={bemBlocks.item("subtitle")}>Released in {source.year}, rated {source.imdbRating}/10</h3>
        <div className={bemBlocks.item("text")} dangerouslySetInnerHTML={{__html:source.plot}}></div>
      </div>
    </div>
  )
}
