import React from "react";

import {
  SearchkitManager, SearchkitProvider,
  SearchBox, Hits, Pagination,
  GroupedSelectedFilters, InputFilter,
  MenuFilter, HitsStats, NoHits,
  ItemList, CheckboxItemList, ItemHistogramList,
  Tabs, TagCloud, Toggle, Select,
  Layout, LayoutBody, LayoutResults,
  SideBar, TopBar,
  ActionBar, ActionBarRow
} from "searchkit";
import { RefinementAutosuggest } from "@searchkit/refinement-autosuggest"

import { ProfileHitsGridItem, ProfileHitsListItem } from "./ResultComponents"

import "searchkit/theming/theme.scss";
import "./customisations.scss";


const host = "https://hrspider-backend.9tb.de/xing/"
const searchkit = new SearchkitManager(host, {
  basicAuth: "admin:modomoto19",
  timeout: 20000
})

export default class App extends React.Component<any, any> {
  render(){
    return (
      <SearchkitProvider searchkit={searchkit}>
        <Layout className="list-app">
          <TopBar>
            <div className="my-logo">HRSPIDER</div>

            <SearchBox
              autofocus={true}
              searchOnChange={true}
              searchThrottleTime={750}
              prefixQueryOptions={{type: "cross_fields"}}
              prefixQueryFields={["family_name^10", "given_name^9", "company^7", "job_title^5", "offers^2"]} />
          </TopBar>

          <LayoutBody>
            <SideBar>
              <div className="sk-layout__filters-row">
                <InputFilter
                  id="name"
                  title="Vor-/Nachname"
                  searchOnChange={true}
                  prefixQueryFields={["given_name", "family_name"]}
                  queryFields={["given_name", "family_name"]}
                />
                <RefinementAutosuggest
                  field={"offers.raw"}
                  id="offers"
                  title="Person bietet"
                  multi={true} />
                <RefinementAutosuggest
                  field={"seeks.raw"}
                  id="seeks"
                  title="Person sucht"
                  multi={true} />
                <RefinementAutosuggest
                  field={"interests"}
                  id="interests"
                  title="Interessen"
                  multi={true} />
                <RefinementAutosuggest
                  field={"education.school_name"}
                  id="school_name"
                  title="Hochschulen"
                  multi={true} />
              </div>
              <div className="sk-layout__filters-row">
                <RefinementAutosuggest
                  field={"company.raw"}
                  id="company_current"
                  title="Unternehmen (aktuell)"
                  multi={true} />
                <RefinementAutosuggest
                  field={"job_title.raw"}
                  id="job_title_current"
                  title="Position (aktuell)"
                  multi={true} />
                <RefinementAutosuggest
                  field={"work_experience.company.name.raw"}
                  id="company_previous"
                  title="Unternehmen (zuvor)"
                  multi={true} />
                <RefinementAutosuggest
                  field={"work_experience.role.raw"}
                  id="job_title_previous"
                  title="Position (zuvor)"
                  multi={true} />
              </div>
              <div className="sk-layout__filters-row">
                <RefinementAutosuggest
                  field={"location.city.raw"}
                  id="city"
                  title="Ort (aktuell)"
                  multi={true} />
                <RefinementAutosuggest
                  field={"languages.name.raw"}
                  id="languages"
                  title="Sprachkenntnisse"
                  multi={true} />
              </div>
              <div className="sk-layout__filters-row">
                {/* <MenuFilter field={"network"} title="ItemList" id="item-list" listComponent={ItemList} />
                <MenuFilter field={"network"} title="CheckboxItemList" id="checkbox-item-list" listComponent={TagCloud} />
                <MenuFilter field={"network"} title="ItemHistogramList" id="histogram-list" listComponent={ItemHistogramList} /> */}
                <MenuFilter field={"company.raw"} title="Unternehmen" id="menufilter-company" listComponent={CheckboxItemList} />
                <MenuFilter field={"job_title.raw"} title="Positionen" id="menufilter-job-title" listComponent={CheckboxItemList} />
                <MenuFilter field={"offers.raw"} title="Person bietet" id="menufilter-offers" listComponent={CheckboxItemList} />
                {/* <MenuFilter field={"network"} title="Toggle" id="toggle" listComponent={Toggle} />
                <MenuFilter field={"network"} title="Select" id="select" listComponent={Select} /> */}
              </div>
            </SideBar>

            <LayoutResults>
              <ActionBar>
                <ActionBarRow>
                  <MenuFilter field={"network"} title="Tabs" id="tabs" listComponent={Tabs} />
                </ActionBarRow>
                <ActionBarRow>
                  <HitsStats translations={{"hitstats.results_found":"{hitCount} results found in {timeTaken} ms"}} />
                </ActionBarRow>
                <ActionBarRow>
                  <GroupedSelectedFilters />
                </ActionBarRow>
              </ActionBar>
              <Hits
                  hitsPerPage={12} highlightFields={["title","plot"]}
                  sourceFilter={["image", "given_name", "family_name", "username"]}
                  mod="sk-hits-grid" itemComponent={ProfileHitsGridItem} scrollTo="body"
              />
              <NoHits suggestionsField={"family_name"}/>
              <Pagination showNumbers={true} />
            </LayoutResults>
          </LayoutBody>
        </Layout>
      </SearchkitProvider>
    )
  }
}
